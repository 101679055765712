import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation_es from "translations/es/translation.json";
import translation_ca from "translations/ca/translation.json";
import translation_en from "translations/en/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  ca: {
    translation: translation_ca,
  },
  es: {
    translation: translation_es,
  },
    en : {
      translation: translation_en,
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    whitelist: ["es", "ca","en"],
    fallbackLng: ["es"],
    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
